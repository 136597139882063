/**
 * The Hello component exists as an example of how to create maintainable
 * and testable graphql code in React. It can and should be deleted
 * from your project once you understand its principles.
 */

import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import Loading from '#client/components/Loading';
import useHelloQuery from './useHelloQuery';

function Hello({ name, sx }) {
  const { loading, hello } = useHelloQuery({ name });

  if (!name) return null;

  if (loading) {
    return (
      <Paper elevation={3} sx={sx}>
        <Box sx={{ p: 2 }}>&nbsp;</Box>
        <Loading />
      </Paper>
    );
  }

  return hello && (
    <Paper sx={{ ...sx, p: 2, boxShadow: 3 }}>{hello}</Paper>
  );
}

Hello.propTypes = {
  name: PropTypes.string,
  sx: PropTypes.shape({})
};

export default Hello;
