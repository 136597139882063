/**
 * The Account component exists as an example of how interface with the
 * application-commons-authentication package and context for fetching user data,
 * as well as how to write component tests against MockAuthentication.
 *
 * It can and should be deleted from your project once you understand its principles.
 */

import Stack from '@mui/material/Stack';
import { useAuthentication } from '@zenbusiness/application-commons-authentication';
import { useIdentity } from '@zenbusiness/application-commons-identity';
import useEnv from '@zenbusiness/application-commons-env';
import Title from '@zenbusiness/common-components/Title';
import Hello from '#client/debugging/Hello';
import { Item, Key, Value } from './Item';

function Account() {
  const { user: $junk1, authentication: $junk2, ...auth } = useAuthentication();
  const { user: $junk3, ...identity } = useIdentity();
  const ENV = useEnv();

  return (
    <Stack sx={{ ml: 2, mr: 2 }}>
      <Title variant="sm" color="growth40" mt={2}>GraphQL Request</Title>
      <Hello name={[identity.firstName, identity.lastName].join(' ')} sx={{ mt: 2 }} />

      <Title variant="sm" color="growth40" mt={2}>Authentication Context</Title>
      {Object.entries(auth).map(([key, value]) => typeof value !== 'function' && (
        <Item key={key}>
          <Key>{`auth.${key}`}</Key>
          <Value data-testid={`auth.${key}`}>{JSON.stringify(value)}</Value>
        </Item>
      ))}

      <Title variant="sm" color="growth40" mt={2}>Identity Context</Title>
      {Object.entries(identity).map(([key, value]) => (
        <Item key={key}>
          <Key>{`identity.${key}`}</Key>
          <Value data-testid={`identity.${key}`}>{String(value)}</Value>
        </Item>
      ))}

      <Title variant="sm" color="growth40" mt={2}>Environment Context</Title>
      {Object.entries(ENV).map(([key, value]) => (
        <Item key={key}>
          <Key>{`ENV.${key}`}</Key>
          <Value data-testid={`ENV.${key}`}>{String(value)}</Value>
        </Item>
      ))}

    </Stack>
  );
}

export default Account;
