import { useCallback } from 'react';

import { useAuthentication } from '@zenbusiness/application-commons-authentication';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import useEnv from '@zenbusiness/application-commons-env';
import Account from './Account';

const FlexRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
});

const FlexColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

function Landing() {
  const { DEFAULT_ROUTE } = useEnv();
  const { authenticated, logout, login } = useAuthentication();

  const onLogoutClick = useCallback(() => { logout(DEFAULT_ROUTE); }, [logout, DEFAULT_ROUTE]);
  const onLoginClick = useCallback(() => { login(DEFAULT_ROUTE); }, [login, DEFAULT_ROUTE]);

  return (
    <FlexColumn pb={2}>
      <FlexRow justifyContent="end" m={1}>
        {authenticated ? (
          <Button onClick={onLogoutClick} variant="outlined">Logout</Button>
        ) : (
          <Button onClick={onLoginClick} variant="outlined">Login</Button>
        )}
      </FlexRow>
      <Account />
    </FlexColumn>
  );
}

export default Landing;
