import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
  boxShadow: theme.shadows[2]
}));

export const Key = styled(Typography)`
  font-weight: bold;
`;

export const Value = styled(Typography)`
  word-break: break-word;
  font-size: 1rem;
  line-height: 1rem;
`;
